import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React from 'react';
import Masonry from 'react-responsive-masonry';
import styled from 'styled-components';
import InstanceStatus from './components/InstanceStatus';
import { PingdomCheck } from './types';

interface Instance {
    type: string;
    name: string;
    url: string;
    pingdomId: string;
}

interface Environment {
    name: string;
    instances: Instance[];
}

interface Installation {
    name: string;
    environments: Environment[];
}

const mapInstances = (instances: PingdomCheck[]): Installation[] => {
    // Create a map to group instances
    const installationsMap = new Map<string, Map<string, Instance[]>>();

    instances.forEach(instance => {
        // Extract tags
        const tags = instance.tags.map(tag => tag.name);
        const installation = tags.find(t => t.startsWith('installation:'))?.split(':')[1];
        const env = tags.find(t => t.startsWith('env:'))?.split(':')[1];
        const appType = tags.find(t => t.startsWith('app_type:'))?.split(':')[1];

        // Skip instances without required tags
        if (!installation || !env || !appType) {
            return;
        }

        // Create nested structure
        if (!installationsMap.has(installation)) {
            installationsMap.set(installation, new Map());
        }
        const envMap = installationsMap.get(installation)!;
        if (!envMap.has(env)) {
            envMap.set(env, []);
        }
        // Add instance to the structure with all necessary props
        envMap.get(env)!.push({
            type: appType,
            name: instance.name,
            url: `https://${instance.hostname}`,
            pingdomId: instance.id.toString()
        });
    });

    // Convert map to desired array structure
    return Array.from(installationsMap.entries())
        .map(([installationName, envMap]) => ({
            name: installationName.toUpperCase(),
            environments: Array.from(envMap.entries())
                .sort((a, b) => b[0] === 'prod' ? 1 : -1) // Sort environments (prod first)
                .map(([envName, instances]) => ({
                    name: envName.toUpperCase(),
                    instances: instances.sort((a, b) => {
                        if (a.type === 'iaso' && b.type !== 'iaso') return -1;
                        if (a.type !== 'iaso' && b.type === 'iaso') return 1;
                        return a.type.localeCompare(b.type);
                    }) // Sort instances by type, iaso first
                }))
        }))
        .sort((a, b) => a.name.localeCompare(b.name)); // Sort installations alphabetically
};

const Container = styled.div`
    margin: 1rem;
    // Add padding to create space between masonry columns
    & > div > div {
        padding: 0 0.75rem;
    }
`;

const InstallationCard = styled.div`
  margin-bottom: 1.5rem; // Increased from 1rem to create more vertical space
`;

const InstallationTitle = styled.h1`
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
`;

const EnvironmentsGrid = styled.div`
    display: grid;
    gap: 0.75rem;
`;

const EnvironmentCard = styled.div`
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    padding: 0.75rem;
    background-color: #f9fafb;
`;

const EnvironmentTitle = styled.h2`
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
`;

const InstancesGrid = styled.div`
    display: grid;
    gap: 0.75rem;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    
    @media (max-width: 767px) {
        grid-template-columns: 1fr;
    }
`;

const App: React.FC = () => {
    const queryKey = ['pingdomChecks'];

    const { data: installations = [] as Installation[], error } = useQuery<Installation[], Error>({
        queryKey,
        queryFn: async () => {
            const response = await axios.get(`/api/pingdom/config`, { withCredentials: true });
            return mapInstances(response.data.checks);
        }
    });

    // Ensure installations is treated as Installation[]
    const installationsArray = installations as Installation[];

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    return (
        <Container>
            <Masonry
                columnsCount={3}
            >
                {installationsArray.map((installation: Installation) => (
                    <InstallationCard key={installation.name}>
                        <InstallationTitle>
                            {installation.name}
                        </InstallationTitle>
                        <EnvironmentsGrid>
                            {installation.environments.map(environment => (
                                <EnvironmentCard key={environment.name}>
                                    <EnvironmentTitle>
                                        {environment.name}
                                    </EnvironmentTitle>
                                    <InstancesGrid>
                                        {environment.instances.map(instance => (
                                            <InstanceStatus
                                                key={instance.pingdomId}
                                                instance={instance}
                                            />
                                        ))}
                                    </InstancesGrid>
                                </EnvironmentCard>
                            ))}
                        </EnvironmentsGrid>
                    </InstallationCard>
                ))}
            </Masonry>
        </Container>
    );
};

export default App;
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useState } from 'react';
import styled from 'styled-components';
import { AWSResponse, IasoInstance, PingdomCheck } from '../types';

interface StatusCardProps {
    $isUp: boolean | null; 
}

const StatusCard = styled.a<StatusCardProps>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 160px;
    padding: 0.75rem;
    border-radius: 0.375rem;
    background-color: ${props =>
        props.$isUp === null ? '#f5f5f5' :
        props.$isUp ? '#e7f5e7' : '#ffe6e6'};
    border: 1px solid ${props =>
        props.$isUp === null ? '#ddd' :
        props.$isUp ? '#4caf50' : '#ff5252'};
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    transition: transform 0.2s;
    box-sizing: border-box;

    &:hover {
        transform: scale(1.02);
    }
`;

const StatusIndicator = styled.span<StatusCardProps>`
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 0.25rem;
    background-color: ${props =>
        props.$isUp === null ? '#ddd' :
        props.$isUp ? '#4caf50' : '#ff5252'};
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    font-size: 0.75rem;
    margin-top: auto;
`;

const TableRow = styled.tr`
    &:not(:last-child) td {
        padding-bottom: 0.125rem;
    }
`;

const Title = styled.h3`
    margin: 0 0 0.25rem 0;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
`;

const Label = styled.td`
    font-weight: 500;
    padding-right: 0.25rem;
    vertical-align: top;
    white-space: nowrap;
    color: #4b5563;
`;

const Value = styled.td`
    word-break: break-word;
`;

interface SummaryStats {
    totalup: number;
    totaldown: number;
    totalunknown: number;
}

interface Props {
    instance: IasoInstance;
}


const formatDowntime = (seconds: number): string => {
    if (seconds === 0) return '--';

    const days = Math.floor(seconds / (24 * 3600));
    const hours = Math.floor((seconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    const parts = [];
    if (days > 0) parts.push(`${days} day${days !== 1 ? 's' : ''}`);
    if (hours > 0) parts.push(`${hours} hour${hours !== 1 ? 's' : ''}`);
    if (minutes > 0) parts.push(`${minutes} minute${minutes !== 1 ? 's' : ''}`);

    return parts.join(', ');
};

const calculateUptimePercentage = (stats: SummaryStats): string => {
    const total = stats.totalup + stats.totaldown + stats.totalunknown;
    const percentage = (stats.totalup / total) * 100;
    return percentage.toFixed(2);
};
const InstanceStatus: React.FC<Props> = ({ instance }) => {

    const [lastCheck, setLastCheck] = useState<string | null>(null);
    const queryKeyDetails = ['details', instance.pingdomId];
    const { data: check, error } = useQuery<PingdomCheck, Error>({
        queryKey: queryKeyDetails,
        queryFn: async () => {
            setLastCheck(new Date().toLocaleString());
            const response = await axios.get(`/api/pingdom/${instance.pingdomId}`, { withCredentials: true });
            return response.data.check;
        },
        refetchInterval: 60000,
    });

    const queryKeySummaryStats = ['summaryStats', instance.pingdomId];


    const { data: summaryStats } = useQuery<SummaryStats, Error>({
        queryKey: queryKeySummaryStats,
        queryFn: async () => {
            const response = await axios.get(`/api/pingdom/${instance.pingdomId}/summary`, { withCredentials: true });
            return response.data.summary.status;
        },
        refetchInterval: 6000000,
    });
    const queryKeyAwsStatus = ['awsStatus', instance.pingdomId];

    const descriptionMatch = check?.custom_message?.match(/ebId:([a-zA-Z0-9-]+)/);
    const { data: awsStatus } = useQuery<AWSResponse, Error>({
        queryKey: queryKeyAwsStatus,
        queryFn: async () => {
            if (!descriptionMatch) return null;
            const awsEnvId = descriptionMatch[1];
            const response = await axios.get(`/api/aws/eb/status/${awsEnvId}`, { withCredentials: true });
            return response.data;
        },
        refetchInterval: 6000000,
        enabled: Boolean(descriptionMatch),
    });
    if (!check) return null;
    return (
        <StatusCard $isUp={check.status === 'up' ? true : check.status === 'down' ? false : null} href={instance.url} target="_blank" rel="noopener noreferrer">
            <Title>
                <StatusIndicator $isUp={check.status === 'up' ? true : check.status === 'down' ? false : null} />
                {instance.name}
            </Title>
            <Table>
                <tbody>
                    <TableRow>
                        <Label>Status:</Label>
                        <Value>{check?.status === null ? 'Loading...' : check?.status ? 'Up' : 'Down'}</Value>
                    </TableRow>
                    {check?.lastresponsetime && (
                        <TableRow>
                            <Label>Response Time:</Label>
                            <Value>{check?.lastresponsetime}ms</Value>
                        </TableRow>
                    )}
                    {lastCheck && (
                        <TableRow>
                            <Label>Last checked:</Label>
                            <Value>{lastCheck}</Value>
                        </TableRow>
                    )}
                    {error && (
                        <TableRow>
                            <Label>Error:</Label>
                            <Value style={{ color: 'red' }}>{String(error)}</Value>
                        </TableRow>
                    )}
                    {summaryStats && (
                        <>
                            <TableRow>
                                <Label>Uptime:</Label>
                                <Value>{calculateUptimePercentage(summaryStats)}%</Value>
                            </TableRow>
                            <TableRow>
                                <Label>Downtime:</Label>
                                <Value>{formatDowntime(summaryStats.totaldown)}</Value>
                            </TableRow>
                            <TableRow>
                                <Label>Unknown:</Label>
                                <Value>{formatDowntime(summaryStats.totalunknown)}</Value>
                            </TableRow>
                        </>
                    )}
                    {awsStatus && (
                        <TableRow>
                            <Label>AWS Status:</Label>
                            <Value>
                                <StatusIndicator
                                    $isUp={awsStatus.health.toLowerCase() === 'green'}
                                    style={{marginRight: '0.5rem'}}
                                />
                                {awsStatus.status} ({awsStatus.health})
                            </Value>
                        </TableRow>
                    )}
                </tbody>
            </Table>
        </StatusCard>
    );
};

export default InstanceStatus;